import React, { useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Col, Row } from "react-bootstrap";
import Campaigns from "./Campaigns";

const AllProjects = () => {
    
  return (
    <Col md={12}>
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="all" title="All">
          <Campaigns status="all"/>
        </Tab>

        <Tab eventKey="contact" title="Pending" >
            <Campaigns status="pending"/>
        </Tab>
        <Tab eventKey="completed" title="Completed">
            <Campaigns status="completed" />
        </Tab>
        <Tab eventKey="active" title="Active">
            <Campaigns status="Accepted" />
        </Tab>
      </Tabs>
    </Col>
  );
};

export default AllProjects;
