import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaigns } from "../actions";
import { Row, Col, Modal } from "react-bootstrap";
import ViewApplicant from "./ViewApplicant";
import CreateCampaign from "../components/CreateCampaign";
import { Link } from "react-router-dom";

const ModalC = ({ campaignDetail, show, handleClose }) => {
  // const dateFromDB = campaignDetail.date;
  // const formattedDate = dateFromDB.toISOString().split('T')[0];

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{campaignDetail.campaign_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>
            <span className="modal-key">Product Id: </span>
            <span className="modal-value">{campaignDetail.Id}</span>
          </li>
          <li>
            <span className="modal-key">Product Name: </span>
            <span className="modal-value">{campaignDetail.brand_name}</span>
          </li>
          <li>
            <span className="modal-key">Created On: </span>
            <span className="modal-value">
              {campaignDetail.date} &nbsp; {campaignDetail.time}
            </span>
          </li>
          <li>
            <span className="modal-key">Description: </span>
            <span className="modal-value">
              {campaignDetail.brand_guidlines}
            </span>
          </li>
          <li>
            <span className="modal-key">Logo: </span>
            <span className="modal-value">{campaignDetail.Logo}</span>
          </li>
          <li>
            <span className="modal-key">No of meme needed: </span>
            <span className="modal-value">
              {campaignDetail.static_meme +
                campaignDetail.video_meme +
                campaignDetail.gif}
            </span>
          </li>

          <li>
            <span className="modal-key">Time Limit: </span>
            <span className="modal-value">{campaignDetail.time_limit}</span>
          </li>
          <li>
            <span className="modal-key">Status: </span>
            <span className="modal-value">{campaignDetail.Status}</span>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};
const Campaigns = ({ status }) => {
  let allCampaign = useSelector((state) => state.campaign.allCampaign);
  let pendingCampaign = useSelector((state) => state.campaign.pendingCampaign);
  let activeCampaign = useSelector((state) => state.campaign.activeCampaign);
  // let  pendingCampaign = useSelector(state => state.campaign.pendingCampaign)
  let data;
  if (status === "all" || status === undefined) {
    data = allCampaign;
  } else if (status === "pending") {
    data = pendingCampaign;
  } else if (status === "completed") {
    data = [];
  } else if (status === "Accepted") {
    data = activeCampaign;
  }
  const [campaignDetail, setCampaignDetail] = useState({});
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [editshow,setEditshow]=useState(false)
  const [updatedata,setUpdatedata]=useState({})

  const handleClose = (e) => {
    setShow(false);
  };
  const handleClosec = (e) => {
    setEditshow(false);
  };
  useEffect(() => {
    dispatch(fetchCampaigns());
  }, [dispatch]);

  const modal = (i) => {
    setShow(true);
    setCampaignDetail(i);
  };
  const handledit = async(i)=>{
    


  }
  return (
    <>
      {show && (
        <ModalC
          campaignDetail={campaignDetail}
          show={show}
          handleClose={handleClose}
        />
      )}
      {editshow && <CreateCampaign handleClose={handleClosec}  action={'Update'} data={updatedata} />}

      <Col sm={12}>
        <Row>
          {data !== undefined &&
            data.map((i) => (
              <Col sm={6} md={3}>
                <div className="dashboard-count" >
                  <div className="dashboard-count-heading" style={{flex:1,flexDirection:'row',marginBottom:10}} >
                  
                    <p>{i.campaign_name}</p>
                    <i className="fas fa-edit" onClick={()=>{
setEditshow(true)
setUpdatedata(i)
                      
                    }}></i>
                    {i.Status == "Accepted" ? (
                      // Render ViewApplicant component if status is Accepted
                      <Link
                        to={{
                          pathname: "/dashboard/view-applicants",
                          search: `?Id=${i.campaign_name}`,
                        }}
                      >
                        Applicants
                      </Link>
                    ) : null}
                  </div>
                  <div className="dashboard-count-content status" >
                    <p>Status: {i.Status}</p>

                
                      <a className='custom-btn' onClick={() => modal(i)}>View</a>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Col>
    </>
    // <div className="campaign-table">
    //     <BootstrapTable
    //         bootstrap4
    //         keyField="id"
    //         data={data !== undefined && (status != undefined ? data.pendingCampaign : data.allCampaign)}
    //         columns={columns}
    //         pagination={paginationFactory({ sizePerPage: 5 })}
    //     />
    // </div>
  );
};

export default Campaigns;
