import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DefaultImg from "../images/blog-default.jpeg";
import { SocialLinks } from "../components/SocialLinks";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { config } from "../components/Constant";
const BlogDetail = () => {
  const { id } = useParams();
  const [item,setItem]=useState({})
  
  useEffect(() => {
    const fetchBlogData = async () => {
        
      try {
       
        const res = await axios.get(`${config.API_URL}/admin/getblog/${id}`);
        setItem(res.data.data[0]);

        
      } catch (error) {
      }
    };

    fetchBlogData();
  }, [id]);
  
  const link = "https://marqueberry.com/";
  const parser = new DOMParser();
  const contentDoc = parser.parseFromString(item?.Content, "text/html");

  const addBreaks = (element) => {
    if (
      element.tagName === "P" ||
      element.tagName === "DIV" ||
      element.tagName === "UL" ||
      element.tagName === "OL"
    ) {
      element.insertAdjacentHTML("afterend", "<br>");
    }
    element.childNodes.forEach((child) => {
      if (child.nodeType === 1) {
        addBreaks(child);
      }
    });
  };

  useEffect(() => {
    const contentElement = contentDoc.body;
    addBreaks(contentElement);
  }, [contentDoc]);

  return (
    <section className="blog-detail">
      <Container>
        <Row style={{ justifyContent: "center", marginRight: "0" }}>
          <Col md={8} className="mx-auto">
            <div className="blog-detail-heading">
              <h1>{item.Header}</h1>
            </div>
          </Col>
          <Col md={8}>
            <div className="blog-detail-image">
              <img
                src={
                  item.Image !== null
                    ? `${link}marqueberryblog/${item.Image}`
                    : DefaultImg
                }
                className="card-img-top"
                alt=""
                style={{ maxHeight: "50%", maxWidth: "50%" }}
              />
            </div>
            <div
              className="blog-detail-description"
              style={{
                fontSize: "16px",
                lineHeight: "1.6",
                marginBottom: "200px",
              }}
              dangerouslySetInnerHTML={{ __html: contentDoc.body.innerHTML }}
            />
            <div className="blog-detail-share text-center">
              <h3>Don’t forget to share this post!</h3>
              <ul>
                <SocialLinks share={true} url={window.location.href} />
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
            }

export default BlogDetail;
