import React from "react";

// export default function Policy() {
//   return (
//     <div style={{ padding: "20px" }}>
//       <h1>Privacy Policy</h1>
//       <p>Effective Date: 1 June 2024</p>
//       <h2>Introduction</h2>
//       <p>
//         Welcome to Adoro ("we," "our," "us"). We value your privacy and are
//         committed to protecting your personal information. This Privacy Policy
//         ("Policy") outlines the types of information we collect from users
//         ("you," "your") of our website, adoro.social, and our mobile application
//         ("App"), and how we use, disclose, and safeguard that information. By
//         accessing or using our Site and App, you agree to the terms of this
//         Policy.
//       </p>
//       <h2>Information We Collect</h2>
//       <h3>Personal Data</h3>
//       <p>
//         We may collect personally identifiable information ("Personal Data")
//         that you provide to us when you register for an account, use our
//         services, or interact with our Site and App. This information may
//         include:
//       </p>
//       <ul>
//         <li>Name</li>
//         <li>Email address</li>
//         <li>Phone number</li>
//         <li>Photographs, videos, and other media content</li>
//         <li>Bank account details and payment information</li>
//       </ul>
//       <h3>Usage Data</h3>
//       <p>
//         We may collect non-personally identifiable information about your
//         interactions with our Site and App ("Usage Data"). This may include:
//       </p>
//       <ul>
//         <li>Device type, model, and manufacturer</li>
//         <li>IP address</li>
//         <li>Operating system and browser type</li>
//         <li>Location data</li>
//         <li>Access times and dates</li>
//         <li>Pages viewed and actions taken</li>
//       </ul>
//       <h3>Cookies and Tracking Technologies</h3>
//       <p>
//         We use cookies and similar tracking technologies to collect and store
//         information about your interactions with our Site and App. Cookies are
//         small data files stored on your device that help us improve your
//         experience by remembering your preferences and settings.
//       </p>
//       <h3>Referral Data</h3>
//       In addition to the information listed above, we may collect data related
//       to our "Refer and Earn" program. This data may include:
//       <p>
//         Referral links you share or receive Information about the users who join
//         our Site and App through your referral link The rewards or benefits you
//         earn through the referral program
//       </p>
//       <h2>Use of Your Information</h2>
//       <p>
//         We use the information we collect to provide, maintain, and improve our
//         services, and to develop new ones. Specifically, we may use your
//         information to:
//       </p>
//       <ul>
//         <li>Create and manage your account</li>
//         <li>Process your transactions</li>
//         <li>Communicate with you about your account and our services</li>
//         <li>Send you updates, promotions, and marketing materials</li>
//         <li>Respond to your inquiries and provide customer support</li>
//         <li>Personalize your experience on our Site and App</li>
//         <li>Analyze usage patterns and improve our Site and App</li>
//         <li>Prevent fraudulent transactions and protect against theft</li>
//         <li>Comply with legal obligations and protect our legal rights</li>
//         <li>
//           We use the information we collect to provide, maintain, and improve
//           our services, and to develop new ones. Specifically, we may use your
//           information to: Facilitate the "Refer and Earn" Program: Track
//           referrals, credit your account with rewards or benefits, and
//           communicate with you about the program's status and updates.
//         </li>{" "}
//       </ul>
//       <h2>Disclosure of Your Information</h2>
//       <p>
//         We may share your information with third parties under certain
//         circumstances. We will disclose your information as described below:
//       </p>
//       <ul>
//         <li>
//           <strong>With Your Consent:</strong> We may share your information with
//           third parties if you have given us your explicit consent to do so.
//         </li>
//         <li>
//           <strong>Service Providers:</strong> We may share your information with
//           third-party service providers who perform services on our behalf, such
//           as payment processing, data analysis, email delivery, hosting
//           services, customer service, and marketing assistance. These providers
//           are contractually obligated to protect your information and use it
//           only for the purposes we specify.
//         </li>
//         <li>
//           <strong>Business Transfers:</strong> We may share or transfer your
//           information in connection with, or during negotiations of, any merger,
//           sale of company assets, financing, or acquisition of all or a portion
//           of our business to another company.
//         </li>
//         <li>
//           <strong>Legal Obligations:</strong> We may disclose your information
//           if required to do so by law or in response to valid requests by public
//           authorities (e.g., a court or a government agency).
//         </li>
//         <li>
//           <strong>Referral Data Sharing::</strong> If you participate in our
//           "Refer and Earn" program, we may share your referral code or link with
//           individuals you refer or those who refer you, for the purpose of
//           tracking and rewarding referrals.
//         </li>
//         <li>
//           <strong>Protection of Rights:</strong> We may disclose your
//           information to protect and defend our rights, property, or safety, or
//           that of our users or others, in connection with investigating and
//           preventing fraud or other illegal activities.
//         </li>
//       </ul>
//       <h2>Security of Your Information</h2>
//       <p>
//         We implement a variety of security measures to maintain the safety of
//         your personal information. However, no method of transmission over the
//         Internet, or method of electronic storage, is 100% secure. While we
//         strive to use commercially acceptable means to protect your personal
//         information, we cannot guarantee its absolute security. By using our
//         Site and App, you acknowledge and agree to assume these risks.
//       </p>
//       <h2>Data Retention</h2>
//       <p>
//         We will retain your Personal Data only for as long as is necessary for
//         the purposes set out in this Policy. We will retain and use your
//         information to the extent necessary to comply with our legal obligations
//         (for example, if we are required to retain your data to comply with
//         applicable laws), resolve disputes, and enforce our legal agreements and
//         policies.
//       </p>
//       <h2>International Data Transfers</h2>
//       <p>
//         Your information, including Personal Data, may be transferred to and
//         maintained on computers located outside of your state, province,
//         country, or other governmental jurisdiction where the data protection
//         laws may differ from those of your jurisdiction. By submitting your
//         information to us, you agree to this transfer, storing, and processing
//         of your information.
//       </p>
//       <h2>Your Data Protection Rights</h2>
//       <p>
//         Depending on your location, you may have the following rights regarding
//         your Personal Data:
//       </p>
//       <ul>
//         <li>
//           <strong>The right to access:</strong> You have the right to request
//           copies of your personal information.
//         </li>
//         <li>
//           <strong>The right to rectification:</strong> You have the right to
//           request that we correct any information you believe is inaccurate or
//           complete information you believe is incomplete.
//         </li>
//         <li>
//           <strong>The right to erasure:</strong> You have the right to request
//           that we erase your personal information, under certain conditions.
//         </li>
//         <li>
//           <strong>The right to restrict processing:</strong> You have the right
//           to request that we restrict the processing of your personal
//           information, under certain conditions.
//         </li>
//         <li>
//           <strong>The right to object to processing:</strong> You have the right
//           to object to our processing of your personal information, under
//           certain conditions.
//         </li>
//         <li>
//           <strong>The right to data portability:</strong> You have the right to
//           request that we transfer the data that we have collected to another
//           organization, or directly to you, under certain conditions.
//         </li>
//       </ul>
//       <p>
//         To exercise any of these rights, please contact us at our contact
//         information provided below. We may require you to verify your identity
//         before responding to such requests.
//       </p>
//       <h2>Children's Privacy</h2>
//       <p>
//         Our Site and App are not intended for use by children under the age of
//         13. We do not knowingly collect personally identifiable information from
//         children under 13. If we learn that we have collected personal
//         information from a child under age 13 without verification of parental
//         consent, we will delete that information as quickly as possible. If you
//         believe we might have any information from or about a child under 13,
//         please contact us immediately.
//       </p>
//       <h2>Changes to This Privacy Policy</h2>
//       <p>
//         We may update this Privacy Policy from time to time to reflect changes
//         in our practices, technologies, legal requirements, and other factors.
//         When we make changes to this Policy, we will revise the "Effective Date"
//         at the top of this page and post the updated Policy on our Site and App.
//         We encourage you to review this Policy periodically to stay informed
//         about how we are protecting your information.
//       </p>
//       <h2>Contact Us</h2>
//       <p>
//         If you have any questions or concerns about this Privacy Policy, please
//         contact us at:
//       </p>
//       <p>
//         Adoro
//         <br />
//         Email: info@adoro.social
//         <br />
//         Phone: 7400705595
//         <br />
//         Website: <a href="https://adoro.social">https://adoro.social</a>
//       </p>
//     </div>
//   );
// }

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> 1 June 2024</p>

      <h2>Introduction</h2>
      <p>Welcome to Adoro ("we," "our," "us"). We value your privacy and are committed to protecting your personal information. This Privacy Policy ("Policy") outlines the types of information we collect from users ("you," "your") of our website, adoro.social, and our mobile application ("App"), and how we use, disclose, and safeguard that information. By accessing or using our Site and App, you agree to the terms of this Policy.</p>

      <h2>Information We Collect</h2>
      <h3>Personal Data</h3>
      <p>We may collect personally identifiable information ("Personal Data") that you provide to us when you register for an account, use our services, or interact with our Site and App. This information may include:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Photographs, videos, and other media content</li>
        <li>Bank account details and payment information</li>
      </ul>

      <h3>Usage Data</h3>
      <p>We may collect non-personally identifiable information about your interactions with our Site and App ("Usage Data"). This may include:</p>
      <ul>
        <li>Device type, model, and manufacturer</li>
        <li>IP address</li>
        <li>Operating system and browser type</li>
        <li>Location data</li>
        <li>Access times and dates</li>
        <li>Pages viewed and actions taken</li>
      </ul>

      <h3>Cookies and Tracking Technologies</h3>
      <p>We use cookies and similar tracking technologies to collect and store information about your interactions with our Site and App. Cookies are small data files stored on your device that help us improve your experience by remembering your preferences and settings.</p>

      <h2>Use of Your Information</h2>
      <p>We use the information we collect to provide, maintain, and improve our services, and to develop new ones. Specifically, we may use your information to:</p>
      <ul>
        <li>Create and manage your account</li>
        <li>Process your transactions</li>
        <li>Communicate with you about your account and our services</li>
        <li>Send you updates, promotions, and marketing materials</li>
        <li>Respond to your inquiries and provide customer support</li>
        <li>Personalize your experience on our Site and App</li>
        <li>Analyze usage patterns and improve our Site and App</li>
        <li>Prevent fraudulent transactions and protect against theft</li>
        <li>Comply with legal obligations and protect our legal rights</li>
      </ul>

      <h2>Refer and Earn Program</h2>
      <p>If you participate in our Refer and Earn program, we may collect additional information such as the referral code you use or share, and details about the person you referred, such as their name and email address. This information is used to track and manage your participation in the program, including awarding referral bonuses. By participating in this program, you agree that the person you refer may be informed of your referral.</p>

      <h2>Sharing Content</h2>
      <p>When you share content through our Site and App, such as photographs, videos, and other media content, that content may be visible to other users depending on your privacy settings. We may use and display this content as part of our services and marketing efforts, but we will always respect your privacy settings.</p>

      <h2>Creating Content</h2>
      <p>If you create content on our Site and App, such as posts, comments, or media uploads, we may collect and store this content. This content may be associated with your account and displayed publicly or privately depending on your chosen settings. We may use this content to improve our services, for marketing purposes, or as otherwise described in this Policy.</p>

      <h2>Disclosure of Your Information</h2>
      <p>We may share your information with third parties under certain circumstances. We will disclose your information as described below:</p>
      <ul>
        <li><strong>With Your Consent:</strong> We may share your information with third parties if you have given us your explicit consent to do so.</li>
        <li><strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance. These providers are contractually obligated to protect your information and use it only for the purposes we specify.</li>
        <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        <li><strong>Legal Obligations:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
        <li><strong>Protection of Rights:</strong> We may disclose your information to protect and defend our rights, property, or safety, or that of our users or others, in connection with investigating and preventing fraud or other illegal activities.</li>
      </ul>

      <h2>Security of Your Information</h2>
      <p>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. By using our Site and App, you acknowledge and agree to assume these risks.</p>

      <h2>Data Retention</h2>
      <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Policy. We will retain and use your information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>

      <h2>International Data Transfers</h2>
      <p>Your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. By submitting your information to us, you agree to this transfer, storing, and processing of your information.</p>

      <h2>Your Data Protection Rights</h2>
      <p>Depending on your location, you may have the following rights regarding your Personal Data:</p>
      <ul>
        <li><strong>The right to access:</strong> You have the right to request copies of your personal information.</li>
        <li><strong>The right to rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
        <li><strong>The right to erasure:</strong> You have the right to request that we erase your personal information, under certain conditions.</li>
        <li><strong>The right to restrict processing:</strong> You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
        <li><strong>The right to object to processing:</strong> You have the right to object to our processing of your personal information, under certain conditions.</li>
        <li><strong>The right to data portability:</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
      </ul>
      <p>To exercise any of these rights, please contact us at our contact information provided below. We may require you to verify your identity before responding to such requests.</p>

      <h2>Children's Privacy</h2>
      <p>Our Site and App are not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us immediately.</p>

      <h2>Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices, technologies, legal requirements, and other factors. When we make changes to this Policy, we will revise the "Effective Date" at the top of this page and post the updated Policy on our Site and App. We encourage you to review this Policy periodically to stay informed about how we are protecting your information.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
      <p>Adoro</p>
      <p>Email: info@adoro.social</p>
      <p>Phone: 7400705595</p>
      <p>Website: <a href="https://adoro.social">https://adoro.social</a></p>
    </div>
  );
};

export default PrivacyPolicy;

