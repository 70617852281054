// 
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogs } from "../actions";
import DefaultImg from "../images/blog-default.jpeg";

const BlogItem = ({ item }) => {
  const link = "https://marqueberry.com/";
  const encodedHeader = encodeURIComponent(item.Header);
  const replacedString = item.Header.replace(/ /g, '-');

  const parser = new DOMParser();
  const contentDoc = parser.parseFromString(item.Content, 'text/html');

  const contentText = contentDoc.body.innerText.substring(0, 80);

  return (
    <div className="card" style={{ width: "18rem", marginTop: 20 }}>
      <img
        src={
          item?.Image !== null
            ? `${link}marqueberryblog/${item.Image}`
            : DefaultImg
        }
        className="card-img-top"
        alt=""
      />
      <div className="card-body">
        <div className="card-category" dangerouslySetInnerHTML={{ __html: contentText }} />
        <p>...</p>
        <Link to={`/blog/${replacedString}`}>
          {item?.Header}
        </Link>
      </div>
    </div>
  );
};

const Blog = () => {
  const blogs = useSelector((state) => state.blog?.blogs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  if (!blogs) {
    return null;
  }

  const sortedBlogs = blogs.slice().sort((a, b) => b.Id - a.Id);

  return (
    <section className="blog">
      <h2>Blog</h2>
      <Container>
        <Row>
          <Col md={12}>
            <Row className="all-blogs">
              {sortedBlogs.map((i) => (
                <Col key={i.Id} md={4}>
                  <BlogItem item={i} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Blog;
