import React, { useState,useEffect } from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import DefaultImg from '../images/blog-default.jpeg';
import { GetStartedBtn } from '../components/GetStartedBtn';
import { useLocation ,useParams} from 'react-router-dom';
import axios from "axios";
import { config } from "../components/Constant";

const CaseStudyDetail = ({}) => {
  
    const { id } = useParams();
    const [item,setItem]=useState({})
   
    
    useEffect(() => {
      const fetchBlogData = async () => {
       
        try {
     
          const res = await axios.get(`${config.API_URL}/admin/getcase/${id}`);
          setItem(res.data.data[0]);
  
          
        } catch (error) {
          console.error("Error fetching data from the database:", error);
        }
      };
  
      fetchBlogData();
    }, [id]);
    const link = "https://marqueberry.com/";
    const parser = new DOMParser();
    const contentDoc = parser.parseFromString(item?.Content, 'text/html');

    const addBreaks = (element) => {
        if (element.tagName === "P" || element.tagName === "DIV" || element.tagName === "UL" || element.tagName === "OL") {
            element.insertAdjacentHTML('afterend', '<br>');
        }
        element.childNodes.forEach(child => {
            if (child.nodeType === 1) {
                addBreaks(child);
            }
        });
    }
    return(
        <section className="blog-detail">
            <div className="blog-detail-path">
                <p>{item.Header}</p>
            </div>
            <Container>
            <Row style={{ justifyContent: 'center', marginRight: '0' }}>

                    <Col sm={8}>
                        <div className="blog-detail-heading">
                            <h1>{item.Header}</h1>
                            <p>Posted On {item.Date}</p>
                            {/* <p>Posted On {item.created_on.split(" ")[0]}</p> */}
                        </div>
                    </Col>
                    <Col md={8}>
                    <div className="blog-detail-image">
                    {<img src={`${link}marqueberrycasestudy/${item.Image}`} alt=""  style={{ height: "auto", width: '50vw' , maxwidth:"100px"}}/>  }

                        </div>
                        {/* <div className="blog-detail-description">
                            <p>
                                {item.description}
                            </p>
                        </div> */}

                        <div
                            className="blog-detail-description"
                            style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '200px' }}
                            dangerouslySetInnerHTML={{ __html: contentDoc.body.innerHTML }}
                        />
                        <div className='blog-detail-share text-center'>
                            <h3>Want to be next viral brand</h3>
                            <GetStartedBtn />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CaseStudyDetail;