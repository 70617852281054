import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import axios from 'axios';
import { config } from '../components/Constant';
import DefaultLogo from '../images/Trell_logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";


const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick} className={className} style={{ ...style }}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </div>
    )
}

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick} className={className} style={{ ...style }}>
            <FontAwesomeIcon icon={faAngleRight} />
        </div>
    )
}
const settings = {
    infinite: false,
    arrows: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    rows:2,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [{
        breakpoint: 768,
        settings: {
            rows:1,
            slidesToShow: 6,
            slidesToScroll: 6,
        }
    }]
};

const CaseStudy = ({ item, caseStudyDetail }) => {
    const link = "https://marqueberry.com/";
    

    const stringWithSpacesReplaced = item.Image.replace(/ /g, '%20');

   const urlstring= item.Header.replace(/ /g, '-');

   
    const urlFriendlyString = stringWithSpacesReplaced.replace(/:/g, '%3A');
      return (
      <div className='case-study-wrapper'>
        <Link to={`/case-study/${urlstring}`} >
          <div className="case-study" onClick={() => caseStudyDetail(item)}>
            <div className="case-study-logo">
              {item.Image ? (
                <img
                  src={`${link}marqueberrycasestudy/${urlFriendlyString}`}
                  alt={`Image for ${item.Header}`}
                />
              ) : (
                <img src={DefaultLogo} alt="Default Logo" />
              )}
            </div>
          </div>
        </Link>
      </div>
    );
};

const RecentWorks = ({caseStudyDetail}) => {
    const [data, setData] = useState([])

    useEffect(() => {
        axios.get(`${config.API_URL + '/admin/getcaseStudy'}`).then(res => {
            if (res.status === 200) {
                const casestudy = res.data.data.slice().sort((a, b) => b.Id - a.Id);

                setData(casestudy)
          
            }
        });
    }, [])
  
    return (
        <Row className="justify-content-center">
            <Col md={11} sm={12}>
            {
                data?.length !== 0 ? <Slider {...settings}>
                {
                    data?.map((i,key) => (
                        <CaseStudy item={i} key={key} caseStudyDetail={caseStudyDetail}/>
                    ))
                }
            </Slider> : null
            }
                
            </Col>
        </Row>
    )
}

export default RecentWorks;