

import axios from "axios";
import React, { useEffect, useState } from "react";
import { config } from "../components/Constant";

const Template = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true); // Flag to indicate loading state

  const getTemplates = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/app/user/getalltemplates`);
      setTemplates(response.data.posts);
      setLoading(false); // Set loading to false once images are fetched
    } catch (error) {
      console.error("Error fetching templates:", error);
      // Handle errors gracefully
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const renderImageGrid = () => {
    if (loading) {
      return (
        <div className="loading-container">
          {/* Add a loading indicator here, e.g., a spinner */}
          <p>Loading images...</p>
        </div>
      );
    }

    const rows = Math.ceil(templates.length / 3); // Calculate the number of rows

    return (
      <div className="image-grid" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <div key={rowIndex} style={{ }}>
            {templates.slice(rowIndex * 3, (rowIndex + 1) * 3).map((template, index) => (
              <div key={template.id} className="image-item" style={{ flex: 1, marginRight: 10 }}>
                {/* Assuming "imageURL" is the property containing the image URL */}
                <img
                  src={`https://www.adoro.social/Template/Image/${template.fileName}`}
                  alt={template.altText || "Image description"}
                  style={{ height: 200, width: 200 , marginTop:20}}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
     
      {renderImageGrid()}
    </>
  );
};

export default Template;
